export const apiBaseURL = "https://mpa.qr.api.oat.m-pesa.com"


export const userAPIConfig = {
    baseURL: `${apiBaseURL}/usr/v1.0/users`
};

export const auditAPIConfig = {
    baseURL: `${apiBaseURL}/usr/v1.0/audits`
};

export const permissionAPIConfig = {
    baseURL: `${apiBaseURL}/usr/v1.0/permissions`
};

export const roleAPIConfig = {
    baseURL: `${apiBaseURL}/usr/v1.0/roles`
};

export const qrAPIConfig = {
    baseURL: `${apiBaseURL}/qr/v1/`
};


export const languageAPIConfig = {
    baseURL: `${apiBaseURL}/qr/v1/languages`
};